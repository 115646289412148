<script>
  /**
   * Renders the content from the main slot, and if `wrap` is `true`, wraps it in an element with the given `tag`.
   */
  export default {
    functional: true,
    props: {
      /** The tag of the wrapper element. */
      tag: {
        type: String,
        default: 'div',
      },
      /** Whether to render the wrapper element. */
      wrap: {
        type: Boolean,
        default: false,
      },
    },
    render(createElement, context) {
      return context.props.wrap
        ? createElement(context.props.tag, context.data, context.children)
        : context.children
    },
  }
</script>
